var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import omit from 'omit.js';
import BreadcrumbItem from './BreadcrumbItem';
import Menu from '../menu';
import { ConfigConsumer } from '../config-provider';
import warning from '../_util/warning';
function getBreadcrumbName(route, params) {
    if (!route.breadcrumbName) {
        return null;
    }
    const paramsKeys = Object.keys(params).join('|');
    const name = route.breadcrumbName.replace(new RegExp(`:(${paramsKeys})`, 'g'), (replacement, key) => params[key] || replacement);
    return name;
}
function defaultItemRender(route, params, routes, paths) {
    const isLastItem = routes.indexOf(route) === routes.length - 1;
    const name = getBreadcrumbName(route, params);
    return isLastItem ? <span>{name}</span> : <a href={`#/${paths.join('/')}`}>{name}</a>;
}
function filterFragment(children) {
    return toArray(children).map((element) => {
        if (React.isValidElement(element) && element.type === React.Fragment) {
            const props = element.props;
            return props.children;
        }
        return element;
    });
}
export default class Breadcrumb extends React.Component {
    constructor() {
        super(...arguments);
        this.getPath = (path, params) => {
            path = (path || '').replace(/^\//, '');
            Object.keys(params).forEach(key => {
                path = path.replace(`:${key}`, params[key]);
            });
            return path;
        };
        this.addChildPath = (paths, childPath = '', params) => {
            const originalPaths = [...paths];
            const path = this.getPath(childPath, params);
            if (path) {
                originalPaths.push(path);
            }
            return originalPaths;
        };
        this.genForRoutes = ({ routes = [], params = {}, separator, itemRender = defaultItemRender, }) => {
            const paths = [];
            return routes.map(route => {
                const path = this.getPath(route.path, params);
                if (path) {
                    paths.push(path);
                }
                // generated overlay by route.children
                let overlay;
                if (route.children && route.children.length) {
                    overlay = (<Menu>
            {route.children.map(child => (<Menu.Item key={child.breadcrumbName || child.path}>
                {itemRender(child, params, routes, this.addChildPath(paths, child.path, params))}
              </Menu.Item>))}
          </Menu>);
                }
                return (<BreadcrumbItem overlay={overlay} separator={separator} key={route.breadcrumbName || path}>
          {itemRender(route, params, routes, paths)}
        </BreadcrumbItem>);
            });
        };
        this.renderBreadcrumb = ({ getPrefixCls, direction }) => {
            let crumbs;
            const _a = this.props, { prefixCls: customizePrefixCls, separator, style, className, routes, children } = _a, restProps = __rest(_a, ["prefixCls", "separator", "style", "className", "routes", "children"]);
            const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);
            if (routes && routes.length > 0) {
                // generated by route
                crumbs = this.genForRoutes(this.props);
            }
            else if (children) {
                crumbs = React.Children.map(filterFragment(children), (element, index) => {
                    if (!element) {
                        return element;
                    }
                    warning(element.type &&
                        (element.type.__ANT_BREADCRUMB_ITEM === true ||
                            element.type.__ANT_BREADCRUMB_SEPARATOR === true), 'Breadcrumb', "Only accepts Breadcrumb.Item and Breadcrumb.Separator as it's children");
                    return React.cloneElement(element, {
                        separator,
                        key: index,
                    });
                });
            }
            const breadcrumbClassName = classNames(className, prefixCls, {
                [`${prefixCls}-rtl`]: direction === 'rtl',
            });
            return (<div className={breadcrumbClassName} style={style} {...omit(restProps, ['itemRender', 'linkRender', 'nameRender', 'params'])}>
        {crumbs}
      </div>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderBreadcrumb}</ConfigConsumer>;
    }
}
Breadcrumb.defaultProps = {
    separator: '/',
};
